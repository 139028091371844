var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app flex-row align-items-center"},[_c('div',{staticClass:"container"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-card-group',[_c('b-card',{staticClass:"p-4 card-reset",attrs:{"no-body":""}},[_c('b-card-body',[_c('validation-observer',{ref:"resetPasswordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onLogin.apply(null, arguments)}}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('h3',[_c('center',[_vm._v("Restablecer Contraseña Paso 2")])],1),_c('center',[_c('h4',[_vm._v("E-mail con el que estas registrado")])]),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","name":"email","autocomplete":"email","state":errors.length > 0 ? false : null,"placeholder":"Te enviaremos una url a este email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Contraseña"}},[_c('validation-provider',{attrs:{"name":"Contraseña","vid":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"password","name":"password","autocomplete":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Repetir contraseña","description":"puedes usar caracteres especiales, letras y numeros"}},[_c('validation-provider',{attrs:{"name":"Repetir contraseña","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"password","name":"password_confirmation","autocomplete":"password_confirmation","state":errors.length > 0 ? false : null},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('br'),_c('center',[_c('b-button',{staticClass:"md-primary md-width",attrs:{"disabled":invalid},on:{"click":function($event){return _vm.onReset()}}},[_vm._v("Cambiar contraseña")])],1)],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }