<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-card-group>
            <b-card no-body class="p-4 card-reset">
              <b-card-body>
                <validation-observer ref="resetPasswordForm" #default="{invalid}">
                <b-form @submit.prevent="onLogin">
                  <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
                  <h3>
                    <center>Restablecer Contraseña Paso 2</center>
                  </h3>
                  <center>
                    <h4>E-mail con el que estas registrado</h4>
                  </center>

                  <!-- Email -->
                    <b-form-group label="Email">
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email|min:5"
                      >
                        <b-form-input
                          size="sm"
                          type="text"
                          v-model="user.email"
                          name="email"
                          autocomplete="email"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Te enviaremos una url a este email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  
                  <!-- Contraseña -->
                    <b-form-group label="Contraseña">
                      <validation-provider
                        #default="{ errors }"
                        name="Contraseña"
                        vid="password"
                        rules="required|min:8"
                      >
                        <b-form-input
                          size="sm"
                          type="password"
                          v-model="user.password"
                          name="password"
                          autocomplete="password"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  
                  <!-- Repetir contraseña -->
                    <b-form-group label="Repetir contraseña" description="puedes usar caracteres especiales, letras y numeros">
                      <validation-provider
                        #default="{ errors }"
                        name="Repetir contraseña"
                        rules="required|confirmed:password"
                      >
                        <b-form-input
                          size="sm"
                          type="password"
                          v-model="user.password_confirmation"
                          name="password_confirmation"
                          autocomplete="password_confirmation"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  <b-row>
                    <b-col cols="12">
                      <br />
                      <center>
                        <b-button @click="onReset()" :disabled="invalid" class="md-primary md-width">Cambiar contraseña</b-button>
                      </center>
                    </b-col>
                  </b-row>
                </b-form>
                </validation-observer>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from "@validations";
import UserService from "@/services/UserService";

export default {
  name: "ResetPassword",
  components:{
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      required,
      email,
      isLoading: false,
      fullPage: false,
      user: { email: null, password: null, password_confirmation: null }
    };
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    onReset() {
      let timerInterval;
      this.$refs.resetPasswordForm.validate().then(valid => {
        if (valid) {
          this.isLoading = true;
          UserService.reset_password({
            token: this.$route.params.token,
            email: this.user.email,
            password: this.user.password,
            password_confirmation: this.user.password_confirmation
          })
            .then(response => {
              this.isLoading = false;
              switch (response.data.message) {
                case "Failed, Invalid Token.": {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Datos invalidos`,
                      text: `Token invalido o vencido`,
                      icon: "AlertOctagonIcon",
                      variant: "danger",
                    },
                  });
                  break;
                }
                default: {
                  this.$swal({
                    title: "Contraseña actualizada",
                    type: "success",
                    html: "seras redireccionado en <strong></strong> segundos.",
                    timer: 5000,
                    onBeforeOpen: () => {
                      this.$swal.showLoading();
                      timerInterval = setInterval(() => {
                        this.$swal
                          .getContent()
                          .querySelector("strong").textContent = Math.floor(
                          this.$swal.getTimerLeft() / 1000
                        );
                      }, 100);
                    },
                    onClose: () => {
                      clearInterval(timerInterval);
                    }
                  }).then(() => {
                    this.$router.push("/");
                  });
                }
              }
            })
            .catch(reason => {
              this.isLoading = false;
              if (reason.response.data) {
                switch (reason.response.data.message) {
                  case "Please Verify Email": {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Revisa tu bandeja de emails`,
                        text: `Es necesario Verificar el email`,
                        icon: "AlertTriangleIcon",
                        variant: "warning",
                      },
                    });
                    break;
                  }
                  case "Account Disabled": {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Usuario deshabilitado`,
                        text: `El usuario aun esta pendiente por activar`,
                        icon: "AlertTriangleIcon",
                        variant: "warning",
                      },
                    });
                    break;
                  }
                  case "The given data was invalid.": {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Datos invalidos`,
                        text: `Alguno de los datos proporcionados son invalidos`,
                        icon: "AlertOctagonIcon",
                        variant: "danger",
                      },
                    });
                    break;
                  }
                }
              }
            });
        }
      });
    }
  },
  computed: {}
};
</script>
<style lang="scss">
.card-reset {
  border: 0;
  border-radius: 1rem;
  .card-body h3 {
    line-height: 1.5em;
    color: #f6770d;
    font-size: 1.73125rem;
    font-weight: 700;
  }
}
.bg-gray-default {
  background: #f3f3f3;
}
.md-primary {
  background-image: linear-gradient(
    to right,
    #f68d1e 5%,
    #f05822 100%
  ) !important;
}
h6 {
  color: #838890;
}
// .card-body {
//   /*  padding-left: 2rem;
//   padding-right: 2rem; */
// }
.btn-link {
  font-size: 0.7rem;
  text-decoration: underline;
}
.center-abs {
  position: absolute;
  top: 100;
  left: 100;
  right: 100;
  bottom: 100;
}
.py-8 {
  height: auto;
  background-image: url("/img/mask-header.svg"), url("/img/header.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: inherit;
  color: white;
}
</style>
